import React, { useState, useEffect } from 'react'
import '../../../assets/components/TACCT/BL/addSubcategory.scss'
import HeaderNav from '../../HeaderNav'
import { LdsTile, LdsToast, LdsToastProvider, LdsCheckbox, LdsCheckboxGroup, LdsIcon, LdsLink, LdsScrollingCheckboxGroup, LdsSelect, LdsCard, LdsSwitch, LdsTextField, LdsButton, useLdsModal, useToastContext, LdsModal, LdsLoadingSpinner, LdsTable, LdsValidationError, LdsRadio } from '@elilillyco/ux-lds-react'
import { fetchCountries } from '../../../store/Components/TACCT/BL/tacctGetCountries'
import { fetchProducts } from '../../../store/Components/TACCT/BL/tacctGetProducts'
import { fetchCategories } from '../../../store/Components/TACCT/BL/tacctGetCategories'
import { updateSubCategoryData } from '../../../store/Components/TACCT/BL/tacctUpdateSubcategory'

import { checkDuplicate } from '../../../store/Components/TACCT/BL/tacctCheckDuplicate'
import { useDispatch, useSelector } from 'react-redux'
import tacctCatOpDefinition, { fetchCatOpDef } from '../../../store/Components/TACCT/BL/tacctCatOpDefinition'
import { createSubCat } from '../../../store/Components/TACCT/BL/tacctCreateSubcategory'
import { fetchSubCategoryByID } from '../../../store/Components/TACCT/BL/tacctGetSubcategoryByID'
import ReactSelect from 'react-select'


export default function AddSubcategory() {
  const subcategory = useSelector(({ tacctGetSubcategoryByID }) => tacctGetSubcategoryByID.Categories);
  const duplicate = useSelector(({ tacctDuplicate }) => tacctDuplicate.duplicates)
  const dup = duplicate[0]
  const dup_loading = useSelector(({ tacctDuplicate }) => tacctDuplicate.loading)
  const categoryList = useSelector(({ tacctCategories }) => tacctCategories.Categories);
  const catOpDef = useSelector(({ tacctCatOpDef }) => tacctCatOpDef.Definition)
  const catDefinition = catOpDef[0]
  const [product, setProduct] = useState('');
  const [prodSearch, setProdSearch] = useState()
  const [newproductList, setNewProductList] = useState()


  const [selectedCat, setSelectedCat] = useState('');
  // const arr = [1042,1043,1044]
  const [checkedValue, setcheckedValue] = useState([])
  const [radioInput, setRadioInput] = useState("CCML")

  const allProduct = useSelector(({ tacctProducts }) => tacctProducts.Products);
  const productList = [...allProduct.map(option =>
    (option.active_flag === true && option.param_key === 'BL' && option.group_purpose_code === radioInput)
      ? { value: option.param_key_val_id, label: option.param_val }
      : null)
  ].filter(Boolean);
  const productsLoading = useSelector(({ tacctProducts }) => tacctProducts.loading);
  const country = useSelector(({ tacctCountries }) => tacctCountries.countryNumber);
  const countryLoading = useSelector(({ tacctCountries }) => tacctCountries.loading);
  const categoryLoading = useSelector(({ tacctCategories }) => tacctCategories.loading);
  const [subCat, setSubCat] = useState([{ id: 1, sub_cat: '', sub_cat_def: '', vvpm_flag: false, vvmc_flag: false, aem_flag: false }])
  const [numberSC, SetNumberSC] = useState(1)
  const role2 = useSelector(({ user }) => user.role_ids);
  // const categoryList = [{ value: '', label: 'Select Option' }, ...categories.map((entry, index) => ({ value: (index + 1), label: entry.name }))]
  const productL = [{ value: '', label: 'Select Option' }, ...allProduct.map((entry) => ({ value: entry.param_key_val_id, label: entry.param_val }))]
  const { addToast } = useToastContext();
  const { isModalOpen: isSubmitModalOpen, setIsModalOpen: setSubmitIsModalOpen } = useLdsModal();
  const { isModalOpen: isDraftModalOpen, setIsModalOpen: setDraftIsModalOpen } = useLdsModal();
  const [selectedProduct, setselectedProduct] = useState('');
  const [selectedCat2, setSelectedCat2] = useState('');
  const URLParams = new URLSearchParams(window.location.search);
  const id = URLParams.get('id');
  const toastConfig = (errTxtMsg, msgType) => {
    return {
      toastMessage: errTxtMsg,
      actionText: "",
      actionCallback: () => { },
      variant: msgType,
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 5000,
      inline: false,
      autoDismiss: true,
    }
  };


  const [countryArr, setcountryArr] = useState('');
  // const countryArr = subcategory.country_id;
  // const countryArr = "1042,1043,1044";
  const [subCat2, setSubCat2] = useState(subcategory.subcategory);
  const [subCatdef2, setSubCatdef2] = useState(subcategory.subcategory_definition);
  const [vvpm_flag, setvvpm_flag] = useState(subcategory.vvpm_flag);
  const [vvmc_flag, setvvmc_flag] = useState(subcategory.vvmc_flag);
  const [aem_flag, setaem_flag] = useState(subcategory.aem_flag);
  const subcategory2 = subcategory;
  const catLoading = useSelector(({ tacctGetSubcategoryByID }) => tacctGetSubcategoryByID.catLoading);
  const [countryList, setCountryList] = useState(country)
  useEffect(() => {
    setCountryList(country)
  }, [country])

  useEffect(() => {
    setvvpm_flag(subcategory.vvpm_flag);
    setvvmc_flag(subcategory.vvmc_flag);
    setaem_flag(subcategory.aem_flag);
  }, [subcategory])

  const [getcountry, setCountry] = useState()
  const [getproduct, setproduct] = useState()
  const [getcategory, setcategory] = useState()
  const [getsubcategory, setsubcategory] = useState()
  const [getsubcategory_def, setsubcategory_def] = useState()
  const emptySubCat = subCat.some(obj => obj.sub_cat === "")
  const emptySubCatDef = subCat.some(obj => obj.sub_cat_def === "")
  const regex = /^[a-zA-Z0-9-_ ]*$/;
  const isValid = subCat.every(subcat => {
    // const regex = /^[a-zA-Z0-9-_ ]*$/;
    return regex.test(subcat.sub_cat) && regex.test(subcat.sub_cat_def);
  });


  const checkSubmitFilled = () => {
    if (id) {
      if (subCat2 === '' || subCatdef2 === '' || checkedValue.length === 0) {
        return addToast(toastConfig("Please add required input fields", "error"))
      }
      const sameEditVals = ((subcategory.subcategory === subCat2) && (subcategory.subcategory_definition === subCatdef2));
      if (!regex.test(subCat2) || !regex.test(subCatdef2)) {
        return addToast(toastConfig("No special characters except '-' and '_'", "error"))
      }
      else {
        const payloadedit = {
          contentFormData: {
            categoryValues: { countryIds: checkedValue, product_id: subcategory.product_id, category: subcategory.category, cat_definition: subcategory.category_definition },
            subcategoryValues: [{
              aem_flag: aem_flag,
              sub_cat: subCat2,
              sub_cat_def: subCatdef2,
              vvmc_flag: vvmc_flag,
              vvpm_flag: vvpm_flag
            }]
          }
        }
        dispatch(checkDuplicate(payloadedit))
      }
      setSubmitIsModalOpen(true);
    }


    else {
      if ((checkedValue.length === 0) || product === '' || product === 'Select Option' || selectedCat === '' || selectedCat === 'Select Option' || emptySubCat || emptySubCatDef) {
        return addToast(toastConfig("Please add required input fields", "error"))
      }
      else if (!isValid) {
        return addToast(toastConfig("No special characters except '-' and '_'", "error"))
      }
      else {

        runCheck()
        setSubmitIsModalOpen(true)
      }
    }

  }
  const checkDraftFilled = () => {
    if (id) {
      // if (subCat2 === '' || subCatdef2 === '') {
      //   return addToast(toastConfig("Please add required input fields", "error"))
      // }
      const sameEditVals = ((subcategory.subcategory === subCat2) && (subcategory.subcategory_definition === subCatdef2));
      if (!regex.test(subCat2) || !regex.test(subCatdef2)) {
        return addToast(toastConfig("No special characters except '-' and '_'", "error"))
      }
      else {
        const payloadedit = {
          contentFormData: {
            categoryValues: { countryIds: checkedValue, product_id: subcategory.product_id, category: subcategory.category, cat_definition: subcategory.category_definition },
            subcategoryValues: [{
              aem_flag: aem_flag,
              sub_cat: subCat2,
              sub_cat_def: subCatdef2,
              vvmc_flag: vvmc_flag,
              vvpm_flag: vvpm_flag
            }]
          }
        }
        // dispatch(checkDuplicate(payloadedit))
      }
      setDraftIsModalOpen(true);
    }
    else {
      if ((checkedValue.length === 0) && (product === '' || product === 'Select Option') && (selectedCat === '' || selectedCat === 'Select Option') && emptySubCat && emptySubCatDef) {
        return checkedValue.length === 0 ? setCountry(true) : setCountry(false),
          product === '' ? setproduct(true) : setproduct(false),
          selectedCat === '' || selectedCat === 'Select Option' ? setcategory(true) : setcategory(false),
          emptySubCat ? setsubcategory(true) : setsubcategory_def(false),
          emptySubCatDef ? setsubcategory_def(true) : setsubcategory_def(false)

      }
      else if (!isValid) {
        return addToast(toastConfig("No special characters except '-' and '_'", "error"))
      }
      else {

        // runCheck()
        setDraftIsModalOpen(true)
      }

    }

  }


  const finalSubCat = subCat.map((obj) => {
    const { id, sub_cat, sub_cat_def, ...rest } = obj;
    return {
      ...rest,
      sub_cat: sub_cat.trim().replace(/\s{2,}/g, ' '),
      sub_cat_def: sub_cat_def.trim().replace(/\s{2,}/g, ' ')
    };
  });

  const handleInputChange = (id, field, value) => {
    const updateSubCat = subCat.map((div) => div.id === id ? {
      ...div, [field]: value
    } : div);
    setSubCat(updateSubCat)
  }
  const handleAddSubCat = () => {
    const newSubCat = { id: Date.now(), sub_cat: '', sub_cat_def: '', vvpm_flag: false, vvmc_flag: false, aem_flag: false };
    setSubCat([...subCat, newSubCat])
  }
  const handleDeleteSubCat = (id) => {
    const updatedSubCat = subCat.filter((div) => div.id !== id)
    setSubCat(updatedSubCat)
  }

  function getCurrentUserId() {
    const currentRole = sessionStorage.getItem('role');
    const currentUserData = role2.filter((roleData) => Object.keys(roleData)[0] === currentRole);
    return currentUserData[0][currentRole];
  }
  const submitSubCat = finalSubCat.map(obj => ({
    ...obj,
    status: 'PENDING',
    country_id: checkedValue,
  }))
  const draftSubCat = finalSubCat.map(obj => ({
    ...obj,
    status: 'DRAFT',
    country_id: checkedValue,
  }))


  function runCheck() {
    const payload = {
      contentFormData: {
        categoryValues: { countryIds: checkedValue, product_id: product, category: selectedCat, cat_definition: catDefinition },
        subcategoryValues: finalSubCat
      }
    }
    dispatch(checkDuplicate(payload))
    // window.location.href='/tacct/blue-list'
  }
  async function submitClick() {

    if (id) {
      const editValues = {}
      editValues.aem_flag = aem_flag
      editValues.category_id = subcategory.blue_list_category_detail_id
      editValues.country_id = checkedValue
      editValues.created_by = getCurrentUserId()
      editValues.status = "PENDING"
      editValues.subcategory = subCat2
      editValues.subcategory_definition = subCatdef2
      editValues.subcategory_id = id
      editValues.updated_by = getCurrentUserId()
      editValues.vvmc_flag = vvmc_flag
      editValues.vvpm_flag = vvpm_flag
      const updatedPayload = { editValues };
      setSubmitIsModalOpen(false);
      await dispatch(updateSubCategoryData(updatedPayload), console.log("1234"));

      window.location.href = '/tacct/blue-list'
      return;
    }
    const payload = {
      contentFormData: {
        categoryValues: { product_id: product, category: selectedCat, cat_definition: catDefinition, created_by: getCurrentUserId() },
        subcategoryValues: submitSubCat
      }
    }
    await dispatch(createSubCat(payload), console.log("1234"))
    window.location.href = '/tacct/blue-list'
  }
  function draftClick() {
    if (id) {
      const editValues = {}
      editValues.aem_flag = aem_flag
      editValues.category_id = subcategory.blue_list_category_detail_id
      editValues.country_id = checkedValue
      editValues.created_by = getCurrentUserId()
      editValues.status = "DRAFT"
      editValues.subcategory = subCat2
      editValues.subcategory_definition = subCatdef2
      editValues.subcategory_id = id
      editValues.updated_by = getCurrentUserId()
      editValues.vvmc_flag = vvmc_flag
      editValues.vvpm_flag = vvpm_flag
      const updatedPayload = { editValues };
      setDraftIsModalOpen(false);
      dispatch(updateSubCategoryData(updatedPayload));

      setTimeout(() => { window.location.href = '/tacct/blue-list' }, 1500)
      return;
    }
    else {
      const payload = {
        contentFormData: {
          categoryValues: { product_id: product, category: selectedCat, cat_definition: catDefinition, created_by: getCurrentUserId() },
          subcategoryValues: draftSubCat
        }
      }
      dispatch(createSubCat(payload))
      setTimeout(() => { window.location.href = '/tacct/blue-list' }, 1500)
    }

  }


  function catDef(category) {
    if (category !== 'Select Option') {
      dispatch(fetchCatOpDef({
        selectedCategory: category,
      }))
    }
  }
  async function getSubByID(id) {
    await dispatch(fetchSubCategoryByID(id));
    setSelectedCat2(subcategory.category);
    setcountryArr(subcategory.country_id);
    setselectedProduct(parseInt(subcategory.product_id))
  }

  function handleInputChange2(e) {
    setSubCat2(e);
  }

  function handleInputChange3(e) {
    setSubCatdef2(e);
  }

  function checkedCountry2(val) {
    let isChecked;
    const arr = subcategory.country_id
    arr.map((num) => {
      if (Number(num) === val) {
        isChecked = true;
      }

    })
    return isChecked;
  }
  const dispatch = useDispatch();

  function fetchCountry() {
    dispatch(fetchCountries());
  }

  function fetchProduct() {
    dispatch(fetchProducts());
  }

  function fetchCategory() {
    dispatch(fetchCategories());
  }

  useEffect(() => {
    const action = async () => {
      await fetchCountry();
      await fetchCategory();
      await fetchProduct();
    }
    action()
    const URLParams = new URLSearchParams(window.location.search);
    const id = URLParams.get('id');
    if (id) {
      getSubByID(id);
    }


  }, []);

  useEffect(() => {
    if (countryArr) {
      const arr = countryArr
      const arrofnumber = arr.map((val) => parseInt(val))
      setcheckedValue([...arrofnumber])
    }

  }, [countryArr])

  useEffect(() => {
    setcountryArr(subcategory.country_id);
  }, [subcategory])

  useEffect(() => {
    setselectedProduct(parseInt(subcategory.product_id));
  }, [subcategory])

  useEffect(() => {
    setSelectedCat2(subcategory.category);

  }, [subcategory])


  useEffect(() => {
    setSubCat2(subcategory.subcategory);

  }, [subcategory])


  useEffect(() => {
    setSubCatdef2(subcategory.subcategory_definition);

  }, [subcategory])

  function handleInputChangeFlag(flagName, e) {
    if (flagName === 'vvpm') {
      setvvpm_flag(e);

    }
    if (flagName === 'vvmc') {
      setvvmc_flag(e);

    }
    if (flagName === 'aem') {
      setaem_flag(e);
    }
  }

  function handleMainChange(e) {
    if (e.target.checked) {
      setcheckedValue(countryList.map(obj => obj.param_key_val_id))
    }
    if (!e.target.checked) {
      setcheckedValue([])
    }
  }
  function checkedData(e) {
    if (e.target.checked) {
      if (!checkedValue.includes(parseInt(e.target.value))) {
        setcheckedValue([...checkedValue, parseInt(e.target.value)])
      }
    }
    if (!e.target.checked) {
      if (checkedValue.includes(parseInt(e.target.value))) {
        setcheckedValue(prevcheckedVaue => checkedValue.filter(obj => obj !== parseInt(e.target.value)))
      }
    }
  }

  function loaderFunction() {
    if (id) {
      if (
        subcategory && catLoading && !productsLoading && !categoryLoading && !countryLoading) {
        return true
      }
      else return false;
    }
    else if (!productsLoading && !categoryLoading && !countryLoading) {
      return true;
    }
    else return false;
  }

  const searchCountry = (e) => {
    e.target.value = e.target.value.toUpperCase()
    if (e.target.value !== '') {
      const cty = country.filter((obj) => obj.param_val.includes(e.target.value.toUpperCase()))
      setCountryList(cty)
    }
    if (e.target.value === '') {
      setCountryList(country)
    }
  }

  return (<>
    <div>
      <HeaderNav msg="Blue List Content Category & Subcategory" />
    </div>
    <div className='redirect'>
      <LdsLink href="/tacct/blue-list">
        <LdsIcon
          description="CaretLeft"
          label="search"
          name="CaretLeft"
          inline
        />
        Back to Overview
      </LdsLink></div>
    <div class="container">
      <h2>{id ? 'Edit' : 'Add New'} Content Subcategory Attributes</h2>
    </div>
    {loaderFunction() ?
      <div className='container-add-as '>
        <div className='attributes'>

          <div className='d-flex justify-content-between align-items-left flex-column'>
            <p className='countryName'>Country</p>
            <input type='text' placeholder='Search Countries' className='country-search' onChange={(e) => searchCountry(e)} />
          </div>
          &nbsp;
          <div className='SelectALl'>
            <LdsCheckbox label='Select All' onClick={(e) => handleMainChange(e)} />
          </div>
          <div className='country' >
            {countryList.map((entry, index) =>
              <div className='country-div'>
                {(catLoading && id !== undefined) &&
                  <LdsCheckbox
                    id={entry.param_key_val_id}
                    value={entry.param_key_val_id}
                    label={entry.param_val}
                    name="testCheckbox"
                    defaultChecked={checkedCountry2(parseInt(entry.param_key_val_id))}
                    checked={checkedValue.includes(parseInt(entry.param_key_val_id))}
                    onChange={(e) => { checkedData(e) }}
                  />
                }

                {id == null &&
                  <LdsCheckbox
                    id={entry.param_key_val_id}
                    value={entry.param_key_val_id}
                    label={entry.param_val}
                    name="testCheckbox"
                    checked={checkedValue.includes(parseInt(entry.param_key_val_id))}
                    onChange={(e) => { checkedData(e) }}
                  />
                }

              </div>)}
          </div>
          {getcountry && <LdsValidationError id="test">
            Please select Country
          </LdsValidationError>}

          <div className='extFields mt-3'>
            <div className='d-flex p-2 justify-content-around'>
              <LdsRadio
                id="med"
                label="Medical"
                name="but"
                value="CCML"
                defaultChecked
                onChange={(e) => setRadioInput(e.target.value)}
                error
              />
              <LdsRadio
                id="com"
                label="Commercial"
                name="but"
                value="BRND"
                onChange={(e) => setRadioInput(e.target.value)}
                error
              /></div>

            <div className='p-2'>
              {(!productsLoading && id === null) &&
                (<><label>Product</label>
                  <ReactSelect
                    className="basic-single "
                    classNamePrefix="select"
                    isLoading={false}
                    isSearchable={prodSearch}
                    defaultValue={{ label: 'Select Option' }}
                    options={productList}
                    labe={"Select Product"}
                    onChange={e => { setProduct(e?.value); { e?.value === '' ? setproduct(true) : setproduct(false) } }}
                  /></>)
              }
              {getproduct && product === '' ? <LdsValidationError id="test">
                Please select product
              </LdsValidationError> : ''}
              {(!productsLoading && catLoading && id !== undefined) &&
                <LdsSelect
                  className='select'
                  id="productSelect"
                  label="Product"
                  name="productSelect"
                  options={productL}
                  // value={selectedProduct}
                  value={parseInt(subcategory.product_id)}
                  onChange={e => { setselectedProduct(e.value); { e.value === '' ? setproduct(true) : setproduct(false) } }}
                  error={getproduct && product === ''}
                  errorMessage='Please select Product'
                  disabled="route.query.id !== undefined"
                />
              }
              {(!categoryLoading && id === null) &&
                <LdsSelect
                  className='select cat'
                  id="categorySelect"
                  label="Category"
                  name="categorySelect"
                  options={categoryList}
                  onChange={e => { setSelectedCat(e.label); catDef(e.label); { e.value === "" ? setcategory(true) : setcategory(false) } }}
                  error={getcategory}
                  errorMessage='Please select Category'
                />}
              {(catLoading && id !== undefined) &&
                <LdsSelect
                  className='select cat'
                  id="categorySelect"
                  label="Category"
                  name="categorySelect"
                  options={categoryList}
                  // value={selectedCat2}
                  value={subcategory.category}

                  onChange={e => { setSelectedCat2(e.label); catDef(e.label); { e.value === "" ? setcategory(true) : setcategory(false) } }}
                  error={getcategory}
                  disabled="route.query.id !== undefined"
                  errorMessage='Please select Category'
                />}

              {id === null &&
                <LdsTextField
                  id="defSelect"
                  label="Category Operational Definition"
                  value={catOpDef}
                  readonly
                  required
                />
              }
              {catLoading && id !== undefined &&
                <LdsTextField
                  id="defSelect"
                  label="Category Operational Definition"
                  value={subcategory.category_definition}
                  disabled="route.query.id !== undefined"
                  readonly
                  required
                />
              }
            </div>
          </div>
        </div>
        <div className=' options'>
          {subCat.map((div) => (
            <LdsCard className='tabs'>
              {id === null &&
                <LdsTextField
                  required
                  className='sub-input'
                  id='orgName'
                  name='orgName'
                  label='Subcategory'
                  value={div.sub_cat}
                  error={getsubcategory}
                  onChange={(e) => { handleInputChange(div.id, 'sub_cat', e.target.value); { e.target.value.length === 0 ? setsubcategory(true) : setsubcategory(false) } }}
                />
              }
              {catLoading && id !== undefined &&
                <LdsTextField
                  required
                  className='sub-input'
                  id='orgName'
                  name='orgName'
                  label='Subcategory'
                  value={subCat2}
                  error={getsubcategory}
                  onChange={(e) => { handleInputChange2(e.target.value) }}
                />
              }

              {id === null &&
                <LdsTextField
                  className='sub-def-input'
                  required
                  id='orgName'
                  name='orgName'
                  label='Subcategory Operational Definition'
                  value={div.sub_cat_def}
                  error={getsubcategory_def}
                  onChange={(e) => { handleInputChange(div.id, 'sub_cat_def', e.target.value); { e.target.value.length === 0 ? setsubcategory_def(true) : setsubcategory_def(false) } }}
                />
              }
              {catLoading && id !== undefined &&
                <LdsTextField
                  className='sub-def-input'
                  required
                  id='orgName'
                  name='orgName'
                  label='Subcategory Operational Definition'
                  value={subCatdef2}
                  error={getsubcategory_def}
                  onChange={(e) => { handleInputChange3(e.target.value) }}
                />
              }
              <div className='switch'>
                {id === null &&
                  <LdsSwitch
                    label='VVPM'
                    value={div.vvpm_flag}
                    onChange={(e) => handleInputChange(div.id, 'vvpm_flag', !div.vvpm_flag)}
                  />
                }
                {id === null &&
                  <LdsSwitch
                    label='VVMC'
                    value={div.vvmc_flag}
                    onChange={(e) => handleInputChange(div.id, 'vvmc_flag', !div.vvmc_flag)}
                  />
                }
                {id === null &&
                  <LdsSwitch
                    label='AEM'
                    value={div.aem_flag}
                    onChange={(e) => handleInputChange(div.id, 'aem_flag', !div.aem_flag)}
                  />
                }
                {catLoading && id !== undefined &&
                  <LdsSwitch
                    label='VVPM'
                    onByDefault={(subcategory.vvpm_flag === true || subcategory.vvpm_flag === true) ? true : false}
                    onChange={(e) => { handleInputChangeFlag('vvpm', e.target.checked) }}
                  />
                }
                {catLoading && id !== undefined &&

                  <LdsSwitch
                    label='VVMC'
                    onByDefault={(subcategory.vvmc_flag === true || subcategory.vvmc_flag === true) ? true : false}
                    onChange={(e) => handleInputChangeFlag('vvmc', e.target.checked)}
                  />
                }
                {catLoading && id !== undefined &&

                  <LdsSwitch
                    label='AEM'
                    onByDefault={(subcategory.aem_flag === true || subcategory.aem_flag === true) ? true : false}
                    onChange={(e) => handleInputChangeFlag('aem', e.target.checked)}
                  />
                }
              </div>

              <div className='delete'>{numberSC > 1 && <LdsIcon onClick={() => { handleDeleteSubCat(div.id); SetNumberSC(numberSC - 1) }} name="TrashSimpleFill" />}</div>
            </LdsCard>))}
          {
            !id &&
            <LdsButton onClick={() => { handleAddSubCat(); SetNumberSC(numberSC + 1) }} classes="system" className='add-button'>
              <LdsIcon name='PlusCircleFill' className='add-icon' />
              Add Subcategory
            </LdsButton>
          }

          <div className='save'>
            <LdsButton classes="outlined" disabled={subcategory.status === 'APPROVED' ? true : false} onClick={e => { checkDraftFilled() }}>Save as Draft</LdsButton>
            {dup == undefined && dup_loading == false ?
              <LdsModal
                modalId="testModal"
                open={isDraftModalOpen}
                setModalOpen={setDraftIsModalOpen}
                heading={dup_loading == true ? "Please wait. Checking for duplicates." : "Are you sure you want to draft this request ?"}
              >

                <div className="space">
                  <LdsButton onClick={e => draftClick()}>Yes</LdsButton>
                  <LdsButton onClick={e => setDraftIsModalOpen(false)}>No</LdsButton>
                </div>
              </LdsModal> :
              <LdsModal
                modalId="testModal"
                open={isDraftModalOpen}
                setModalOpen={setDraftIsModalOpen}
                heading={dup_loading == true ? "Please wait. Checking for duplicates." : ""}
              >
                {!dup_loading ?
                  <div>
                    <h3 className='dup-header'>Below Combinations Already Exist</h3>
                    <LdsTable striped>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Category</th>
                          <th>Subcategory</th>
                        </tr>
                      </thead>
                      <tbody>
                        {duplicate.map((entry, index) =>
                          <tr key={index}>
                            <td>{entry.product_name}</td>
                            <td>{entry.category}</td>
                            <td>{entry.sub_cat}</td>
                          </tr>)}
                      </tbody>
                    </LdsTable></div> : <LdsLoadingSpinner />}
              </LdsModal>}
            <LdsButton onClick={e => { checkSubmitFilled() }}>Submit Request</LdsButton>
            <LdsToast />
            {dup == undefined && dup_loading == false ?
              <LdsModal
                modalId="testModal"
                open={isSubmitModalOpen}
                setModalOpen={setSubmitIsModalOpen}
                heading={dup_loading == true ? "Please wait. Checking for duplicates." : "Are you sure you want to request for approval?"}
              >

                <div className="space">
                  <LdsButton onClick={e => submitClick()}>Yes</LdsButton>
                  <LdsButton onClick={e => setSubmitIsModalOpen(false)}>No</LdsButton>
                </div>
              </LdsModal> :
              <LdsModal
                modalId="testModal"
                open={isSubmitModalOpen}
                setModalOpen={setSubmitIsModalOpen}
                heading={dup_loading == true ? "Please wait. Checking for duplicates." : ""}
              >
                {!dup_loading ?
                  <div>
                    <h3 className='dup-header'>Below Combinations Already Exist</h3>
                    <LdsTable striped>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Category</th>
                          <th>Subcategory</th>
                        </tr>
                      </thead>
                      <tbody>
                        {duplicate.map((entry, index) =>
                          <tr key={index}>
                            <td>{entry.product_name}</td>
                            <td>{entry.category}</td>
                            <td>{entry.sub_cat}</td>
                          </tr>)}
                      </tbody>
                    </LdsTable></div> : <LdsLoadingSpinner />}
              </LdsModal>}
          </div>
        </div>
      </div>
      : <LdsLoadingSpinner size={70} class="spinner-add" />}
  </>
  )
}