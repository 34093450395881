import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as RoutePath from '../pages/index';
import Error from '../error/error';
import Layout from '../layout/Layout';
import Middleware from '../middleware/middleware';
import { LdsToast, LdsToastProvider } from '@elilillyco/ux-lds-react';
import { useSelector } from 'react-redux';
import ListScribbing from '../pages/adhoc-list-scrubbing/Index.js'
import ListManagementAuditing from '../pages/list-management-auditing/Index.js'
import History from '../pages/adhoc-list-scrubbing/History.js'
import Index from '../pages/OMMS/index.js';
import Tacct from '../pages/TACCT/index.js';
import Archive from '../components/OMMS/archive.js';
import EditCategory from '../components/TACCT/BL/EditCategory.js';
import RequestForApproval from '../components/OMMS/requestForApproval.js';
import Blue from '../pages/TACCT/blue-list.js';
import TacctRfa from '../components/TACCT/BL/requestForApproval.js';
import TacticEntry from '../components/OMMS/TacticEntry.js';
import AddSubcategory from '../components/TACCT/BL/AddSubcategory.js';
import EditProduct from '../components/TACCT/BL/EditProduct.js';
import AddMetadata from '../components/OMMS/AddMetadata.js';
import BusinessUnitMt from "../components/TACCT/BU/BusinessUnitMt.js"
import { BU } from '../pages/TACCT/business-unit/business-unit-overview/index.js';
import AddEditMetadata from '../components/OMMS/AddEditMetadata.js';
import { GrammaticalFormDef } from '../pages/TACCT/grammatical-form.js';
import ResponseMatrix from '../pages/response-matrix/index.js';
import BUEntry from '../pages/TACCT/business-unit/business-unit-entry/index.js'
import GFEntry from '../pages/TACCT/grammaticalForm/gfEntry.js';
import { GFApproval } from '../pages/TACCT/grammaticalForm/GFApproval.js';
import { BUApproval } from '../pages/TACCT/business-unit/business-unit-approval/index.js';
// import Tonality from '../pages/TACCT/tonality-value/tonality-overview.js';
import { TonalityApproval } from '../pages/TACCT/tonality/tonalityApproval.js';
import Tonality from '../pages/TACCT/tonality/Tonality.js';
import TonalityEntry from '../pages/TACCT/tonality/tonalityEntry.js';
import UsersListPage from '../pages/UsersList.js';
import ExceptionReporting from '../components/OMMS/ExceptionReporting.js';
import ExceptionArchive from '../components/OMMS/ExceptionArchive.js';
import Maintanance from '../error/Maintainance.js';
import { ConfigOmt } from '../pages/configOmt.js';
import OverviewMt from '../components/OMMS/overviewMt.js';
import BlueListMt from '../components/TACCT/BL/BlueListMt.js';
import { useState } from 'react';
import GrammaticalFormMt from '../components/TACCT/GF/GrammaticalFormMt.js';
import TonalityHomeMt from '../components/TACCT/TN/TonalityHomeMt.js';
import Loading from '../components/shared/Loading.js';
import BlArchive from '../components/TACCT/BL/BlArchive.js';
import CAP from '../components/OMMS/capOverview.js';

const RoutesDef = () => {
  const user = useSelector(({ user }) => user.user);

  const omtMt = useSelector(({ omtMtSlice }) => omtMtSlice.MtState);
  const omms_mt= omtMt[0]?.cnfg_val
  const tacct_mt = omtMt[1]?.cnfg_val

  let selectedRole = sessionStorage.getItem('role');

  const [omms_routes, setomms_routes] = useState(<>
              <Route index Component={() => <RoutePath.Home />} />
              <Route path='/omms' Component={() => <Index />} />
              <Route path='/omms/archive' Component={() => <Archive />} />
              <Route path='/omms/tactic-entry' Component={() => <TacticEntry />} />
              <Route path='/omms/requestforapproval' Component={() => <RequestForApproval />} />
              <Route path='/omms/exception-reporting' Component={() => <ExceptionReporting />} />
              <Route path='/omms/exceptionArchive' Component={() => <ExceptionArchive />} />
              <Route path='/omms/add-edit-metadata' Component={() => <AddEditMetadata />} />
              <Route path='/omms/loading' Component={()=> <Loading />} />
              <Route path='/omms/cap-overview' Component={() => <CAP />} />
  </>)
  const [tacct_routes,settacct_routes] = useState(<>
              <Route path='/tacct' Component={() => <Tacct />} />
              <Route path='/tacct/blue-list' Component={() => <Blue />} />
              <Route path='/tacct/edit-category' Component={() => <EditCategory />} />
              <Route path='/tacct/request-approval' Component={() => <TacctRfa />} />
              <Route path='/tacct/tonality-ops/tonality-entry' Component={() => <TonalityEntry />} />
              <Route path='/tacct/add-subcategory-attribute' Component={() => <AddSubcategory />} />
              <Route path='tacct/edit-product' Component={() => <EditProduct />} />
              <Route path='/tacct/business-unit' Component={() => <BU />} />
              <Route path='/tacct/business-unit/bussiness-unit-entry' Component={() => <BUEntry />} />
              <Route path='/tacct/business-unit/approval' Component={() => <BUApproval />} />
              <Route path='/tacct/grammatical-form' Component={() => <GrammaticalFormDef />} />
              <Route path='/tacct/grammatical-form/approval' Component={() => <GFApproval />} />
              <Route path='/tacct/grammatical-form/gfattributes-entry' Component={() => <GFEntry />} />
              <Route path='/tacct/tonality-ops' Component={() => <Tonality />} />
              <Route path='/tacct/bl-archive' Component={() => <BlArchive />} />
              <Route path='/tacct/tonality-ops/approval' Component={() => <TonalityApproval />} />
  </>)
  const [lm_routes,setlm_routes] = useState(<>
             <Route path='/adhoc-List-Scrubbing' Component={() => <ListScribbing />} />
              <Route path='/list-management-auditing' Component={() => <ListManagementAuditing />} />
              <Route path='/adhoc-History' Component={() => <History />} />
              <Route path='/response-matrix' Component={() => <ResponseMatrix />} />
  </>)

  return (
    <LdsToastProvider>
      <BrowserRouter>
        <Middleware>
          
        <Routes>
            {/* Layout */}
            {omms_mt === true && selectedRole !== 'omt_admin' && tacct_mt===true?
            <Route path="/" Component={() => <Layout />} >
            <Route index Component={() => <RoutePath.Home />} />
            <Route path="/omms/" Component={() => <OverviewMt />} />
            <Route path="/omms/*" Component={() => <Maintanance />} />
            <Route path="/config-omt" Component={() => <ConfigOmt />} />
            <Route path='/tacct/blue-list' Component={() => <BlueListMt />} />
              <Route path='/tacct/business-unit' Component={() => <BusinessUnitMt />} />
              <Route path='/tacct/grammatical-form' Component={() => <GrammaticalFormMt />} />
              <Route path='/tacct/tonality-ops' Component={() => <TonalityHomeMt />} />
              <Route path='/tacct' Component={() => <Tacct />} />
              <Route path="/tacct/*" Component={() => <Maintanance />} />
              {lm_routes}
              </Route>
            :omms_mt === true && selectedRole !== 'omt_admin' ?
              <Route path="/" Component={() => <Layout />} >
              <Route index Component={() => <RoutePath.Home />} />
              <Route path="/omms/" Component={() => <OverviewMt />} />
              <Route path="/omms/*" Component={() => <Maintanance />} />
              <Route path="/config-omt" Component={() => <ConfigOmt />} />
              {tacct_routes}
              {lm_routes}
              </Route>
              : tacct_mt===true && selectedRole !=='omt_admin'?
              <Route path="/" Component={() => <Layout />} >
              <Route index Component={() => <RoutePath.Home />} />
              <Route path='/tacct/blue-list' Component={() => <BlueListMt />} />
              <Route path='/tacct/business-unit' Component={() => <BusinessUnitMt />} />
              <Route path='/tacct/grammatical-form' Component={() => <GrammaticalFormMt />} />
              <Route path='/tacct/tonality-ops' Component={() => <TonalityHomeMt />} />
              <Route path='/tacct' Component={() => <Tacct />} />
              <Route path="/tacct/*" Component={() => <Maintanance />} />
              <Route path="/config-omt" Component={() => <ConfigOmt />} />
              {omms_routes}
              {lm_routes}
              </Route>
              :
              <Route path="/" Component={() => <Layout />} >
                <Route path="/config-omt" Component={() => <ConfigOmt />} />
                {selectedRole === 'omms_admin' ? <Route path="/users-list" Component={() => <UsersListPage />} /> : ''}
                {omms_routes}
                {tacct_routes}
                {lm_routes}
                
                {/* Error */}
                <Route path="*" Component={() => <Error />} />
              </Route>
            }
          </Routes>
          
        </Middleware>
      </BrowserRouter>
      <LdsToast />
    </LdsToastProvider>
  );
};

export default RoutesDef;